* {
  font-family: "Rubik", "Ubuntu", "Roboto", "Helvetica", "Arial", "sans-serif";
  margin: 0;
  padding: 0;
}

body {
  background: #FCFCFC;
  margin: 0;
  padding: 0;
  clear: both;
}
